import React from 'react';

const Navbar = ({ translations }) => {

  const aboutIsEnabled = translations?.components?.about?.enabled;
  const servicesIsEnabled = translations?.components?.services?.enabled;
  const estateIsEnabled = translations?.components?.estate?.enabled;
  const portfolioIsEnabled = translations?.components?.portfolio?.enabled;
  const blogIsEnabled = translations?.components?.blog?.enabled;
  const contactIsEnabled = translations?.components?.contact?.enabled;

  const objectTranslations = translations?.components?.navbar?.translations || {};

  const toggleNavigationText = objectTranslations['toggle.navigation'];
  const homeText = objectTranslations['menu.title.home'];
  const aboutText = objectTranslations['menu.title.about'];
  const servicesText = objectTranslations['menu.title.services'];
  const estateText = objectTranslations['menu.title.estate'];
  const portfolioText = objectTranslations['menu.title.portfolio'];
  const blogText = objectTranslations['menu.title.blog'];
  const contactText = objectTranslations['menu.title.contact'];

  // Función para cerrar el menú
  const handleNavItemClick = () => {
    const navbarCollapse = document.querySelector('.navbar-collapse');
    if (navbarCollapse.classList.contains('in')) {
      navbarCollapse.classList.remove('in');
    }
  };

  return (
    <nav className="navbar navbar-default">
      <div className="navbar-header">
        <button
          type="button"
          className="navbar-toggle collapsed"
          data-toggle="collapse"
          data-target=".bs-example-navbar-collapse-1"
          aria-expanded="false"
        >
          <span className="sr-only">{toggleNavigationText}</span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
        <div className="navbar-brand page-scroll sticky-logo">
          <h1>
            <img
              src="img/san-partners-white.svg"
              alt="San & Partners Logo"
              className="logo-img"
            />
            <span className="brand-name">San & </span> Partners
          </h1>
        </div>
      </div>
      <div
        className="collapse navbar-collapse main-menu bs-example-navbar-collapse-1"
        id="navbar-example"
      >
        <ul className="nav navbar-nav navbar-right">
          <li className="active">
            <a className="page-scroll" href="#home" onClick={handleNavItemClick}>
              {homeText}
            </a>
          </li>

          {aboutIsEnabled && (
            <li>
              <a className="page-scroll" href="#about" onClick={handleNavItemClick}>
                {aboutText}
              </a>
            </li>
          )}

          {servicesIsEnabled && (
            <li>
              <a className="page-scroll" href="#services" onClick={handleNavItemClick}>
                {servicesText}
              </a>
            </li>
          )}

          {estateIsEnabled && (
            <li>
              <a className="page-scroll" href="#estate" onClick={handleNavItemClick}>
                {estateText}
              </a>
            </li>
          )}

          {portfolioIsEnabled && (
            <li>
              <a className="page-scroll" href="#portfolio" onClick={handleNavItemClick}>
                {portfolioText}
              </a>
            </li>
          )}

          {blogIsEnabled && (
            <li>
              <a className="page-scroll" href="#blog" onClick={handleNavItemClick}>
                {blogText}
              </a>
            </li>
          )}

          {contactIsEnabled && (
            <li>
              <a className="page-scroll" href="#contact" onClick={handleNavItemClick}>
                {contactText}
              </a>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
