import React from 'react';

const Slider = ({ translations }) => {

  const objectTranslations = translations?.components?.slider?.translations || {};

  const infoTitle1Text = objectTranslations['information.title.1'];
  const infoTitle2Text = objectTranslations['information.title.2'];
  const infoTitle3Text = objectTranslations['information.title.3'];

  const infoBody1Text = objectTranslations['information.body.1'];
  const infoBody2Text = objectTranslations['information.body.2'];
  const infoBody3Text = objectTranslations['information.body.3'];

  const slider1Image = objectTranslations['slider.image.1'];
  const slider2Image = objectTranslations['slider.image.2'];
  const slider3Image = objectTranslations['slider.image.3'];

  return (
    <div id="home" className="slider-area">
      <div className="bend niceties preview-2">
        <div id="ensign-nivoslider" className="slides">
            <img src={ slider1Image } alt="" title="#slider-direction-1" />
            <img src={ slider2Image } alt="" title="#slider-direction-2" />
            <img src={ slider3Image } alt="" title="#slider-direction-3" />
        </div>

        <div id="slider-direction-1" className="slider-direction slider-one">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="slider-content">
                  <div className="layer-1-1 hidden-xs wow slideInDown" data-wow-duration="2s" data-wow-delay=".2s">
                    <h2 className="title1">{ infoTitle1Text }</h2>
                  </div>
                  <div className="layer-1-2 wow slideInUp" data-wow-duration="2s" data-wow-delay=".1s">
                    <h1 className="title2">{ infoBody1Text }</h1>
                  </div>
                  {/*
                  <div className="layer-1-3 hidden-xs wow slideInUp" data-wow-duration="2s" data-wow-delay=".2s">
                    <a className="ready-btn right-btn page-scroll" href="#services">See Services</a>
                    <a className="ready-btn page-scroll" href="#about">Learn More</a>
                  </div>
                  */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="slider-direction-2" className="slider-direction slider-two">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="slider-content text-center">
                  <div className="layer-1-1 hidden-xs wow slideInUp" data-wow-duration="2s" data-wow-delay=".2s">
                    <h2 className="title1">{ infoTitle2Text }</h2>
                  </div>
                  <div className="layer-1-2 wow slideInUp" data-wow-duration="2s" data-wow-delay=".1s">
                    <h1 className="title2">{ infoBody2Text }</h1>
                  </div>
                  {/*
                  <div className="layer-1-3 hidden-xs wow slideInUp" data-wow-duration="2s" data-wow-delay=".2s">
                    <a className="ready-btn right-btn page-scroll" href="#services">See Services</a>
                    <a className="ready-btn page-scroll" href="#about">Learn More</a>
                  </div>
                  */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="slider-direction-3" className="slider-direction slider-two">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="slider-content">
                  <div className="layer-1-1 hidden-xs wow slideInUp" data-wow-duration="2s" data-wow-delay=".2s">
                    <h2 className="title1">{ infoTitle3Text }</h2>
                  </div>
                  <div className="layer-1-2 wow slideInUp" data-wow-duration="2s" data-wow-delay=".1s">
                    <h1 className="title2">{ infoBody3Text }</h1>
                  </div>
                  {/*
                  <div className="layer-1-3 hidden-xs wow slideInUp" data-wow-duration="2s" data-wow-delay=".2s">
                    <a className="ready-btn right-btn page-scroll" href="#services">See Services</a>
                    <a className="ready-btn page-scroll" href="#about">Learn More</a>
                  </div>
                  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
