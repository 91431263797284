import React from 'react';
import { FaEnvelope, FaWhatsapp, FaPhone } from 'react-icons/fa';


const Estate = ({ translations }) => {

  const navbarTranslations = translations?.components?.navbar?.translations || {};
  const estateTitleText = navbarTranslations['menu.title.estate'];

  const contactTranslations = translations?.components?.contact?.translations || {};
  const phone1Text = contactTranslations['contact.phone.1'];
  const phoneNumber = `tel:${phone1Text.trim()}`;

  const whatsappPhoneText = contactTranslations['contact.whatsapp'];
  const whatsappUrl = `https://wa.me/${whatsappPhoneText}?text=Hola%20me%20interesa%20tu%20servicio`;


  const objectTranslations = translations?.components?.estate?.translations || {};

  const estateTitle1Text = objectTranslations['estate.title.1'];
  const estateBody1Text = objectTranslations['estate.body.1'];
  const estate1Image = objectTranslations['estate.image.1'];

  const estateTitle2Text = objectTranslations['estate.title.2'];
  const estateBody2Text = objectTranslations['estate.body.2'];
  const estate2Image = objectTranslations['estate.image.2'];

  const estateTitle3Text = objectTranslations['estate.title.3'];
  const estateBody3Text = objectTranslations['estate.body.3'];
  const estate3Image = objectTranslations['estate.image.3'];

  const estateTitle4Text = objectTranslations['estate.title.4'];
  const estateBody4Text = objectTranslations['estate.body.4'];
  const estate4Image = objectTranslations['estate.image.4'];

  return (
    <div id="estate" className="our-team-area area-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="section-headline text-center">
              <h2>{ estateTitleText }</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="team-top">
            {/* Estate 1 */}
            <div className="col-md-3 col-sm-3 col-xs-12">
              <div className="single-team-member">
                <div className="team-img">
                  <a className="page-scroll" href="#contact">
                    <img src={ estate1Image } alt={ estateTitle1Text } />
                  </a>
                  <div className="team-social-icon text-center">
                    <ul>
                      <li>
                        <a className="page-scroll" href="#contact">
                          <i className="fa"><FaEnvelope /></i> 
                        </a>
                      </li>
                      <li>
                        <a href={ whatsappUrl } target="_blank" rel="noopener noreferrer">
                          <i className="fa"><FaWhatsapp /></i>
                        </a>
                      </li>
                      <li>
                        <a href={ phoneNumber }>
                          <i className="fa"><FaPhone /></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content text-center">
                  <h4>{ estateTitle1Text }</h4>
                  <p>{ estateBody1Text }</p>
                </div>
              </div>
            </div>
            {/* End column */}

            {/* Estate 2 */}
            <div className="col-md-3 col-sm-3 col-xs-12">
              <div className="single-team-member">
                <div className="team-img">
                  <a className="page-scroll" href="#contact">
                    <img src={ estate2Image } alt={ estateTitle2Text } />
                  </a>
                  <div className="team-social-icon text-center">
                    <ul>
                      <li>
                        <a className="page-scroll" href="#contact">
                          <i className="fa"><FaEnvelope /></i> 
                        </a>
                      </li>
                      <li>
                        <a href={ whatsappUrl } target="_blank" rel="noopener noreferrer">
                          <i className="fa"><FaWhatsapp /></i>
                        </a>
                      </li>
                      <li>
                        <a href={ phoneNumber }>
                          <i className="fa"><FaPhone /></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content text-center">
                  <h4>{ estateTitle2Text }</h4>
                  <p>{ estateBody2Text }</p>
                </div>
              </div>
            </div>
            {/* End column */}

            {/* Estate 3 */}
            <div className="col-md-3 col-sm-3 col-xs-12">
              <div className="single-team-member">
                <div className="team-img">
                  <a className="page-scroll" href="#contact">
                    <img src={ estate3Image } alt={ estateTitle3Text } />
                  </a>
                  <div className="team-social-icon text-center">
                    <ul>
                      <li>
                        <a className="page-scroll" href="#contact">
                          <i className="fa"><FaEnvelope /></i> 
                        </a>
                      </li>
                      <li>
                        <a href={ whatsappUrl } target="_blank" rel="noopener noreferrer">
                          <i className="fa"><FaWhatsapp /></i>
                        </a>
                      </li>
                      <li>
                        <a href={ phoneNumber }>
                          <i className="fa"><FaPhone /></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content text-center">
                  <h4>{ estateTitle3Text }</h4>
                  <p>{ estateBody3Text }</p>
                </div>
              </div>
            </div>
            {/* End column */}

            {/* Estate 4 */}
            <div className="col-md-3 col-sm-3 col-xs-12">
              <div className="single-team-member">
                <div className="team-img">
                  <a href="#contact">
                    <img src={ estate4Image } alt={ estateTitle4Text } />
                  </a>
                  <div className="team-social-icon text-center">
                    <ul>
                      <li>
                        <a className="page-scroll" href="#contact">
                          <i className="fa"><FaEnvelope /></i> 
                        </a>
                      </li>
                      <li>
                        <a href={ whatsappUrl } target="_blank" rel="noopener noreferrer">
                          <i className="fa"><FaWhatsapp /></i>
                        </a>
                      </li>
                      <li>
                        <a href={ phoneNumber }>
                          <i className="fa"><FaPhone /></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content text-center">
                  <h4>{ estateTitle4Text }</h4>
                  <p>{ estateBody4Text }</p>
                </div>
              </div>
            </div>
            {/* End column */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Estate;
