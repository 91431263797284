import React from 'react';

const Faq = ({ translations }) => {

  const objectTranslations = translations?.components?.faq?.translations || {};
  
  const faqTitleText = objectTranslations['faq.question.title'];

  const questionTitle1Text = objectTranslations['faq.question.title.1'];
  const questionBody1Text = objectTranslations['faq.question.body.1'];

  const questionTitle2Text = objectTranslations['faq.question.title.2'];
  const questionBody2Text = objectTranslations['faq.question.body.2'];

  const questionTitle3Text = objectTranslations['faq.question.title.3'];
  const questionBody3Text = objectTranslations['faq.question.body.3'];

  const questionTitle4Text = objectTranslations['faq.question.title.4'];
  const questionBody4Text = objectTranslations['faq.question.body.4'];


  const processTitle1Text = objectTranslations['faq.process.title.1'];
  const processBody1Text = objectTranslations['faq.process.body.1'];

  const processTitle2Text = objectTranslations['faq.process.title.2'];
  const processBody2Text = objectTranslations['faq.process.body.2'];

  const processTitle3Text = objectTranslations['faq.process.title.3'];
  const processBody3Text = objectTranslations['faq.process.body.3'];

  return (
    <div className="faq-area area-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="section-headline text-center">
              <h2>{ faqTitleText }</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-6 col-xs-12">
            <div className="faq-details">
              <div className="panel-group" id="accordion">
                {/* Panel Default */}
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="check-title">
                      <a
                        data-toggle="collapse"
                        className="active"
                        data-parent="#accordion"
                        href="#check1"
                      >
                        <span className="acc-icons"></span> { questionTitle1Text }
                      </a>
                    </h4>
                  </div>
                  <div id="check1" className="panel-collapse collapse in">
                    <div className="panel-body">
                      <p>
                        { questionBody1Text }
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Panel Default */}
                {/* Panel Default */}
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="check-title">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#check2"
                      >
                        <span className="acc-icons"></span> { questionTitle2Text }
                      </a>
                    </h4>
                  </div>
                  <div id="check2" className="panel-collapse collapse">
                    <div className="panel-body">
                      <p>
                        { questionBody2Text }
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Panel Default */}
                {/* Panel Default */}
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="check-title">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#check3"
                      >
                        <span className="acc-icons"></span> { questionTitle3Text }
                      </a>
                    </h4>
                  </div>
                  <div id="check3" className="panel-collapse collapse">
                    <div className="panel-body">
                      <p>
                        { questionBody3Text }
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Panel Default */}
                {/* Panel Default */}
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="check-title">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#check4"
                      >
                        <span className="acc-icons"></span> { questionTitle4Text }
                      </a>
                    </h4>
                  </div>
                  <div id="check4" className="panel-collapse collapse">
                    <div className="panel-body">
                      <p>
                        { questionBody4Text }
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Panel Default */}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <div className="tab-menu">
              {/* Nav tabs */}
              <ul className="nav nav-tabs" role="tablist">
                <li className="active">
                  <a href="#p-view-1" role="tab" data-toggle="tab">{ processTitle1Text }</a>
                </li>
                <li>
                  <a href="#p-view-2" role="tab" data-toggle="tab">{ processTitle2Text }</a>
                </li>
                <li>
                  <a href="#p-view-3" role="tab" data-toggle="tab">{ processTitle3Text }</a>
                </li>
              </ul>
            </div>
            <div className="tab-content">
              <div className="tab-pane active" id="p-view-1">
                <div className="tab-inner">
                  <div className="event-content head-team">
                    <h4>{ processTitle1Text }</h4>
                    <p>
                      { processBody1Text }
                   </p>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="p-view-2">
                <div className="tab-inner">
                  <div className="event-content head-team">
                    <h4>{ processTitle2Text }</h4>
                    <p>
                      { processBody2Text }
                   </p>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="p-view-3">
                <div className="tab-inner">
                  <div className="event-content head-team">
                    <h4>{ processTitle3Text }</h4>
                    <p>
                      { processBody3Text }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end Row */}
      </div>
    </div>
  );
};

export default Faq;
