import React from 'react';

const Skills = ({ translations }) => {

  const objectTranslations = translations?.components?.skills?.translations || {};
  
  const skillsTitle1Text = objectTranslations['skills.title.1'];
  const skillsGraphic1Value = objectTranslations['skills.graphic.1'];

  const skillsTitle2Text = objectTranslations['skills.title.2'];
  const skillsGraphic2Value = objectTranslations['skills.graphic.2'];

  const skillsTitle3Text = objectTranslations['skills.title.3'];
  const skillsGraphic3Value = objectTranslations['skills.graphic.3'];

  const skillsTitle4Text = objectTranslations['skills.title.4'];
  const skillsGraphic4Value = objectTranslations['skills.graphic.4'];

  return (
    <div className="our-skill-area fix hidden-sm">
      <div className="test-overly"></div>
      <div className="skill-bg area-padding-2">
        <div className="container">
          <div className="row">
            <div className="skill-text">
              <div className="col-xs-12 col-sm-3 col-md-3 text-center">
                <div className="single-skill">
                  <div className="progress-circular">
                    <input type="text" className="knob" value="0" data-rel={ skillsGraphic1Value } data-linecap="round" data-width="175" data-bgcolor="#fff" data-fgcolor="#3EC1D5" data-thickness=".20" data-readonly="true" disabled />
                    <h3 className="progress-h4">{ skillsTitle1Text }</h3>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-3 col-md-3 text-center">
                <div className="single-skill">
                  <div className="progress-circular">
                    <input type="text" className="knob" value="0" data-rel={ skillsGraphic2Value } data-linecap="round" data-width="175" data-bgcolor="#fff" data-fgcolor="#3EC1D5" data-thickness=".20" data-readonly="true" disabled />
                    <h3 className="progress-h4">{ skillsTitle2Text }</h3>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-3 col-md-3 text-center">
                <div className="single-skill">
                  <div className="progress-circular">
                    <input type="text" className="knob" value="0" data-rel={ skillsGraphic3Value } data-linecap="round" data-width="175" data-bgcolor="#fff" data-fgcolor="#3EC1D5" data-thickness=".20" data-readonly="true" disabled />
                    <h3 className="progress-h4">{ skillsTitle3Text }</h3>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-3 col-md-3 text-center">
                <div className="single-skill">
                  <div className="progress-circular">
                    <input type="text" className="knob" value="0" data-rel={ skillsGraphic4Value } data-linecap="round" data-width="175" data-bgcolor="#fff" data-fgcolor="#3EC1D5" data-thickness=".20" data-readonly="true" disabled />
                    <h3 className="progress-h4">{ skillsTitle4Text }</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
