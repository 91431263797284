import React from 'react';

const Testimonials = ({ translations }) => {

  const testimonialsTranslations = translations?.components?.testimonials?.translations || {};

  const testimonialsComment1Text = testimonialsTranslations['testimonials.comment.1'];
  const testimonialsAuthor1Text = testimonialsTranslations['testimonials.author.1'];

  const testimonialsComment2Text = testimonialsTranslations['testimonials.comment.2'];
  const testimonialsAuthor2Text = testimonialsTranslations['testimonials.author.2'];

  const testimonialsComment3Text = testimonialsTranslations['testimonials.comment.3'];
  const testimonialsAuthor3Text = testimonialsTranslations['testimonials.author.3'];

  return (
    <div className="testimonials-area">
      <div className="testi-inner area-padding">
        <div className="testi-overly"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              {/* Start testimonials */}
              <div className="testimonial-content text-center">
                <div className="quate" href="#">
                  <i className="fa fa-quote-right"></i>
                </div>
                {/* Start testimonial carousel */}
                <div className="testimonial-carousel">
                  <div className="single-testi">
                    <div className="testi-text">
                      <p>
                        { testimonialsComment1Text }
                      </p>
                      <h6>{ testimonialsAuthor1Text }</h6>
                    </div>
                  </div>
                  {/* End single item */}
                  <div className="single-testi">
                    <div className="testi-text">
                      <p>
                        { testimonialsComment2Text }
                      </p>
                      <h6>{ testimonialsAuthor2Text }</h6>
                    </div>
                  </div>
                  {/* End single item */}
                  <div className="single-testi">
                    <div className="testi-text">
                      <p>
                        { testimonialsComment3Text }
                      </p>
                      <h6>{ testimonialsAuthor3Text }</h6>
                    </div>
                  </div>
                  {/* End single item */}
                </div>
                {/* End testimonial carousel */}
              </div>
              {/* End testimonials */}
            </div>
            {/* End Right Feature */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
