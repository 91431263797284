import React, { useEffect, useState } from 'react';

import TranslationService from './services/TranslationService';

import Header from './components/header/Header';
import Slider from './components/slider/Slider';
import About from './components/about/About';
import Services from './components/services/Services';
import Skills from './components/skills/Skills';
import Faq from './components/faq/Faq';
import Newsletter from './components/newsletter/Newsletter';
import Estate from './components/estate/Estate';
import Reviews from './components/reviews/Reviews';
import Portfolio from './components/portfolio/Portfolio';
import Pricing from './components/pricing/Pricing';
import Testimonials from './components/testimonials/Testimonials';
import Blog from './components/blog/Blog';
import Subscribe from './components/subscribe/Subscribe';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';

const App = () => {
  const [translations, setTranslations] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const data = await TranslationService.fetchTranslations();
        setTranslations(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTranslations();
  }, []);

  useEffect(() => {
    if (loading || error) return;

    if (window.$) {
      if (window.WOW) {
        new window.WOW().init();
      }
      
      // Inicializar NivoSlider
      if (Object.keys(translations).length > 0) {
        $('#ensign-nivoslider').nivoSlider({
          effect: 'random',
          slices: 15,
          boxCols: 12,
          boxRows: 8,
          animSpeed: 500,
          pauseTime: 5000,
          startSlide: 0,
          directionNav: true,
          controlNavThumbs: false,
          pauseOnHover: true,
          manualAdvance: false,
        });
      }

      // Inicializar Venobox
      window.$('.venobox').venobox();

      // Inicializar el Scrollspy
      window.$('body').scrollspy({
        target: '.navbar-collapse',
        offset: 80
      });

      // Inicializar el Page Scroll
      window.$('a.page-scroll').on('click', function (event) {
        const $anchor = window.$(this);
        window.$('html, body').stop().animate({
          scrollTop: window.$($anchor.attr('href')).offset().top - 55
        }, 1500, 'easeInOutExpo');
        event.preventDefault();
      });

      // Inicializar el Back to Top button
      window.$(window).scroll(function () {
        if (window.$(this).scrollTop() > 100) {
          window.$('.back-to-top').fadeIn('slow');
        } else {
          window.$('.back-to-top').fadeOut('slow');
        }
      });

      window.$('.back-to-top').click(function () {
        window.$('html, body').animate({ scrollTop: 0 }, 1500, 'easeInOutExpo');
        return false;
      });

      // Inicializar Parallax
      window.$('.wellcome-area').parallax("50%", 0.4);
      window.$('.wellcome-text').parallax("50%", 0.6);

      // Inicializar Collapse
      window.$('.panel-heading a').on('click', function () {
        window.$('.panel-heading a').removeClass('active');
        window.$(this).addClass('active');
      });

      // Inicializar Testimonial Carousel
      window.$('.testimonial-carousel').owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        autoplay: true,
        responsive: {
          0: {
            items: 1
          },
          768: {
            items: 1
          },
          1000: {
            items: 1
          }
        }
      });

      // Inicializar Isotope 
      const $container = window.$('.awesome-project-content');
      $container.isotope({
        filter: '*',
        animationOptions: {
          duration: 750,
          easing: 'linear',
          queue: false,
        },
      });

      window.$('.project-menu li a').on('click', function () {
        const pro_menu_active = window.$('.project-menu li a.active');
        pro_menu_active.removeClass('active');
        window.$(this).addClass('active');
        const selector = window.$(this).attr('data-filter');
        $container.isotope({
          filter: selector,
          animationOptions: {
            duration: 750,
            easing: 'linear',
            queue: false,
          },
        });
        return false;
      });

      // Inicializar Circular Bars - Knob
      if (typeof (window.$.fn.knob) !== 'undefined') {
        window.$('.knob').each(function () {
          const $this = window.$(this);
          const knobVal = $this.attr('data-rel');

          $this.knob({
            'draw': function () {
              $this.val(this.cv + '%');
            }
          });

          $this.appear(function () {
            window.$({ value: 0 }).animate({ value: knobVal }, {
              duration: 2000,
              easing: 'swing',
              step: function () {
                $this.val(Math.ceil(this.value)).trigger('change');
              }
            });
          }, {
            accX: 0,
            accY: -150
          });
        });
      }

      // Inicializar el Sticker
      const s = window.$("#sticker");
      if (s.length) {
        const pos = s.position();

        window.$(window).on('scroll', function () {
          const windowpos = window.$(window).scrollTop();
          if (windowpos > (pos ? pos.top : 0)) {
            s.addClass("stick");
          } else {
            s.removeClass("stick");
          }
        });
      }

      // Inicializar Navbar Nav
      window.$(".main-menu ul.navbar-nav li").on('click', function () {
        window.$(".main-menu ul.navbar-nav li").removeClass("active");
        window.$(this).addClass("active");
      });
    }
  }, [loading, error, translations]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading translations: {error}</div>;
  }

  const headerIsEnabled = translations?.components?.header?.enabled;
  const sliderIsEnabled = translations?.components?.slider?.enabled;
  const aboutIsEnabled = translations?.components?.about?.enabled;
  const servicesIsEnabled = translations?.components?.services?.enabled;
  const skillsIsEnabled = translations?.components?.skills?.enabled;
  const faqIsEnabled = translations?.components?.faq?.enabled;
  const newsletterIsEnabled = translations?.components?.newsletter?.enabled;
  const estateIsEnabled = translations?.components?.estate?.enabled;
  const reviewsIsEnabled = translations?.components?.reviews?.enabled;
  const portfolioIsEnabled = translations?.components?.portfolio?.enabled;
  const pricingIsEnabled = translations?.components?.pricing?.enabled;
  const testimonialsIsEnabled = translations?.components?.testimonials?.enabled;
  const blogIsEnabled = translations?.components?.blog?.enabled;
  const subscribeIsEnabled = translations?.components?.subscribe?.enabled;
  const contactIsEnabled = translations?.components?.contact?.enabled;
  const footerIsEnabled = translations?.components?.footer?.enabled;

  return (
    
    <div>
      {headerIsEnabled && <Header translations={translations} />}
      {sliderIsEnabled && <Slider translations={translations} />}
      {aboutIsEnabled && <About translations={translations} />}
      {servicesIsEnabled && <Services translations={translations} />}
      {skillsIsEnabled && <Skills translations={translations} />}
      {faqIsEnabled && <Faq translations={translations} />}
      {newsletterIsEnabled && <Newsletter translations={translations} />}
      {estateIsEnabled && <Estate translations={translations} />}
      {reviewsIsEnabled && <Reviews translations={translations} />}
      {portfolioIsEnabled && <Portfolio translations={translations} />}
      {pricingIsEnabled && <Pricing />}
      {testimonialsIsEnabled && <Testimonials translations={translations} />}
      {blogIsEnabled && <Blog />}
      {subscribeIsEnabled && <Subscribe />}
      {contactIsEnabled && <Contact translations={translations} />}
      {footerIsEnabled && <Footer translations={translations} />}
    </div>
  );
};

export default App;