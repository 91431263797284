import React from 'react';

const Subscribe = () => {
  return (
    <div className="suscribe-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="suscribe-text text-center">
              <h3>Welcome to our eBusiness company</h3>
              <a className="sus-btn" href="#">Get A quote</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
