import React from 'react';
import { FaShieldAlt, FaChartLine, FaBuilding, FaSearch, FaLock, FaUserTie } from 'react-icons/fa';

const Services = ({ translations }) => {

  const navbarTranslations = translations?.components?.navbar?.translations || {};
  const servicesTitleText = navbarTranslations['menu.title.services'];

  const objectTranslations = translations?.components?.services?.translations || {};
  
  const servicesTitle1Text = objectTranslations['services.title.1'];
  const servicesBody1Text = objectTranslations['services.body.1'];

  const servicesTitle2Text = objectTranslations['services.title.2'];
  const servicesBody2Text = objectTranslations['services.body.2'];

  const servicesTitle3Text = objectTranslations['services.title.3'];
  const servicesBody3Text = objectTranslations['services.body.3'];

  const servicesTitle4Text = objectTranslations['services.title.4'];
  const servicesBody4Text = objectTranslations['services.body.4'];

  const servicesTitle5Text = objectTranslations['services.title.5'];
  const servicesBody5Text = objectTranslations['services.body.5'];

  const servicesTitle6Text = objectTranslations['services.title.6'];
  const servicesBody6Text = objectTranslations['services.body.6'];

  return (
    <div id="services" className="services-area area-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="section-headline services-head text-center">
              <h2>{ servicesTitleText }</h2>
            </div>
          </div>
        </div>
        <div className="row text-center">
          <div className="services-contents">
            {/* Start Left services */}
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="about-move">
                <div className="services-details">
                  <div className="single-services">
                    <div className="services-icon" >
                      <FaShieldAlt size={40} />
                    </div>
                    <h4>{ servicesTitle1Text }</h4>
                    <p>
                      { servicesBody1Text }
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="about-move">
                <div className="services-details">
                  <div className="single-services">
                    <div className="services-icon">
                      <FaChartLine size={40} />
                    </div>
                    <h4>{ servicesTitle2Text }</h4>
                    <p>
                      { servicesBody2Text }                 
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="about-move">
                <div className="services-details">
                  <div className="single-services">
                    <div className="services-icon">
                      <FaBuilding size={40} />
                    </div>
                    <h4>{ servicesTitle3Text }</h4>
                    <p>
                      { servicesBody3Text }
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="about-move">
                <div className="services-details">
                  <div className="single-services">
                    <div className="services-icon">
                      <FaSearch size={40} />
                    </div>
                    <h4>{ servicesTitle4Text }</h4>
                    <p>
                      { servicesBody4Text }
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="about-move">
                <div className="services-details">
                  <div className="single-services">
                    <div className="services-icon">
                      <FaLock size={40} />
                    </div>
                    <h4>{ servicesTitle5Text }</h4>
                    <p>
                      { servicesBody5Text }
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="about-move">
                <div className="services-details">
                  <div className="single-services">
                    <div className="services-icon">
                      <FaUserTie size={40} />
                    </div>
                    <h4>{ servicesTitle6Text }</h4>
                    <p>
                      { servicesBody6Text }
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* End Left services */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
