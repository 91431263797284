import React from 'react';

const Portfolio = ({ translations }) => {
  const navbarTranslations = translations?.components?.navbar?.translations || {};
  const portfolioTitleText = navbarTranslations['menu.title.portfolio'];

  const objectTranslations = translations?.components?.portfolio?.translations || {};

  const portfolioFilter1Text = objectTranslations['portfolio.filter.1'];
  const portfolioFilter2Text = objectTranslations['portfolio.filter.2'];
  const portfolioFilter3Text = objectTranslations['portfolio.filter.3'];
  const portfolioFilter4Text = objectTranslations['portfolio.filter.4'];

  const portfolioTitle1Text = objectTranslations['portfolio.title.1'];
  const portfolioBody1Text = objectTranslations['portfolio.body.1'];
  const portfolio1Image = objectTranslations['portfolio.image.1'];

  const portfolioTitle2Text = objectTranslations['portfolio.title.2'];
  const portfolioBody2Text = objectTranslations['portfolio.body.2'];
  const portfolio2Image = objectTranslations['portfolio.image.2'];

  const portfolioTitle3Text = objectTranslations['portfolio.title.3'];
  const portfolioBody3Text = objectTranslations['portfolio.body.3'];
  const portfolio3Image = objectTranslations['portfolio.image.3'];

  const portfolioTitle4Text = objectTranslations['portfolio.title.4'];
  const portfolioBody4Text = objectTranslations['portfolio.body.4'];
  const portfolio4Image = objectTranslations['portfolio.image.4'];

  const portfolioTitle5Text = objectTranslations['portfolio.title.5'];
  const portfolioBody5Text = objectTranslations['portfolio.body.5'];
  const portfolio5Image = objectTranslations['portfolio.image.5'];


  
  const portfolioTitle6Text = objectTranslations['portfolio.title.6'];
  const portfolioBody6Text = objectTranslations['portfolio.body.6'];
  const portfolio6Image = objectTranslations['portfolio.image.6'];

  return (
    <div id="portfolio" className="portfolio-area area-padding fix">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="section-headline text-center">
              <h2>{portfolioTitleText}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {/* Start Portfolio -page */}
          <div className="awesome-project-1 fix">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="awesome-menu">
                <ul className="project-menu">
                  <li>
                    <a href="#" className="active" data-filter="*">{ portfolioFilter1Text }</a>
                  </li>
                  <li>
                    <a href="#" data-filter=".barcelona">{ portfolioFilter2Text }</a>
                  </li>
                  <li>
                    <a href="#" data-filter=".madrid">{ portfolioFilter3Text }</a>
                  </li>
                  <li>
                    <a href="#" data-filter=".girona">{ portfolioFilter4Text }</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="awesome-project-content">
            {/* single-awesome-project start */}
            <div className="col-md-4 col-sm-4 col-xs-12 barcelona">
              <div className="single-awesome-project">
                <div className="awesome-img">
                  <img src={portfolio1Image} alt={portfolioTitle1Text} />
                  <div className="add-actions text-center">
                    <div className="project-dec">
                      <a className="venobox" data-gall="myGallery" href={portfolio1Image}>
                        <h4>{portfolioTitle1Text}</h4>
                        <span>{portfolioBody1Text}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* single-awesome-project end */}
            {/* single-awesome-project start */}
            <div className="col-md-4 col-sm-4 col-xs-12 barcelona">
              <div className="single-awesome-project">
                <div className="awesome-img">
                  <img src={portfolio2Image} alt={portfolioTitle2Text} />
                  <div className="add-actions text-center">
                    <div className="project-dec">
                      <a className="venobox" data-gall="myGallery" href={portfolio2Image}>
                        <h4>{portfolioTitle2Text}</h4>
                        <span>{portfolioBody2Text}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* single-awesome-project end */}
            {/* single-awesome-project start */}
            <div className="col-md-4 col-sm-4 col-xs-12 barcelona">
              <div className="single-awesome-project">
                <div className="awesome-img">
                  <img src={portfolio3Image} alt={portfolioTitle3Text} />
                  <div className="add-actions text-center">
                    <div className="project-dec">
                      <a className="venobox" data-gall="myGallery" href={portfolio3Image}>
                        <h4>{portfolioTitle3Text}</h4>
                        <span>{portfolioBody3Text}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* single-awesome-project end */}
            {/* single-awesome-project start */}
            <div className="col-md-4 col-sm-4 col-xs-12 madrid">
              <div className="single-awesome-project">
                <div className="awesome-img">
                  <img src={portfolio4Image} alt={portfolioTitle4Text} />
                  <div className="add-actions text-center">
                    <div className="project-dec">
                      <a className="venobox" data-gall="myGallery" href={portfolio4Image}>
                        <h4>{portfolioTitle4Text}</h4>
                        <span>{portfolioBody4Text}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* single-awesome-project end */}
            {/* single-awesome-project start */}
            <div className="col-md-4 col-sm-4 col-xs-12 girona">
              <div className="single-awesome-project">
                <div className="awesome-img">
                  <img src={portfolio5Image} alt={portfolioTitle5Text} />
                  <div className="add-actions text-center">
                    <div className="project-dec">
                      <a className="venobox" data-gall="myGallery" href={portfolio5Image}>
                        <h4>{portfolioTitle5Text}</h4>
                        <span>{portfolioBody5Text}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* single-awesome-project end */}
            {/* single-awesome-project start */}
            <div className="col-md-4 col-sm-4 col-xs-12 girona">
              <div className="single-awesome-project">
                <div className="awesome-img">
                  <img src={portfolio6Image} alt={portfolioTitle6Text} />
                  <div className="add-actions text-center">
                    <div className="project-dec">
                      <a className="venobox" data-gall="myGallery" href={portfolio6Image}>
                        <h4>{portfolioTitle6Text}</h4>
                        <span>{portfolioBody6Text}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* single-awesome-project end */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
