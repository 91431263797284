import React from 'react';

const Reviews = ({ translations }) => {

  const navbarTranslations = translations?.components?.navbar?.translations || {};
  const contactButtonText = navbarTranslations['menu.title.contact'];

  const objectTranslations = translations?.components?.reviews?.translations || {};

  const reviewsTitleText = objectTranslations['reviews.title'];
  const reviewsBodyText = objectTranslations['reviews.body'];
  const aboutImage = objectTranslations['reviews.image.1'];

  return (
    <div className="reviews-area hidden-xs">
      <div className="work-us">
        <div className="work-left-text">
            <img src={ aboutImage } alt={ reviewsTitleText } />
        </div>
        <div className="work-right-text text-center">
          <h2>{ reviewsTitleText }</h2>
          <h5>{ reviewsBodyText }</h5>
          <a href="#contact" className="ready-btn">{ contactButtonText }</a>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
