import React from 'react';

const About = ({ translations }) => {

  const navbarTranslations = translations?.components?.navbar?.translations || {};
  const aboutTilteText = navbarTranslations['menu.title.about'];

  const objectTranslations = translations?.components?.about?.translations || {};
  const about1Image = objectTranslations['about.image.1'];
  const aboutTitleText = objectTranslations['about.title'];
  const aboutBodyText = objectTranslations['about.body'];

  const aboutCheck1Text = objectTranslations['about.check.1'];
  const aboutCheck2Text = objectTranslations['about.check.2'];
  const aboutCheck3Text = objectTranslations['about.check.3'];
  const aboutCheck4Text = objectTranslations['about.check.4'];

  return (
    <div id="about" className="about-area area-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="section-headline text-center">
              <h2>{ aboutTilteText }</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {/* single-well start*/}
          <div className="col-md-6 col-sm-6 col-xs-12">
            <div className="well-left">
              <div className="single-well">
                {/*<a href="#">*/}
                  <img src={ about1Image } alt="" />
                {/*</a>*/}
              </div>
            </div>
          </div>
          {/* single-well end*/}
          <div className="col-md-6 col-sm-6 col-xs-12">
            <div className="well-middle">
              <div className="single-well">
                {/*<a href="#">*/}
                  <h4 className="sec-head">{ aboutTitleText }</h4>
                {/*</a>*/}
                <p>
                  { aboutBodyText }
                </p>
                <ul>
                  <li>
                    <i className="fa fa-check"></i> { aboutCheck1Text }
                  </li>
                  <li>
                    <i className="fa fa-check"></i> { aboutCheck2Text }
                  </li>
                  <li>
                    <i className="fa fa-check"></i> { aboutCheck3Text }
                  </li>
                  <li>
                    <i className="fa fa-check"></i> { aboutCheck4Text }
                  </li>
                  {/*}
                  <li>
                    <i className="fa fa-check"></i> Make Quality Products
                  </li>
                  */}
                </ul>
              </div>
            </div>
          </div>
          {/* End col*/}
        </div>
      </div>
    </div>
  );
};

export default About;
