import React, { useState } from 'react';
import { FaEnvelope, FaWhatsapp, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';
import ContactService from '../../services/ContactService';

const Contact = ({ translations }) => {
  const [formData, setFormData] = useState({
    contactName: '',
    email: '',
    locale: navigator.language,
    phone: '',
    message: '',
    pageId: 1,
    selectedTypeOfInvestment: [],
    selectedTicket: [],
  });

  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [checkboxError, setCheckboxError] = useState(''); // Error si no se selecciona un checkbox

  const navbarTranslations = translations?.components?.navbar?.translations || {};
  const contactTitleText = navbarTranslations['menu.title.contact'];

  const contactTranslations = translations?.components?.contact?.translations || {};
  const phone1Text = contactTranslations['contact.phone.1'];
  const emailText = contactTranslations['contact.email.1'];
  const locationText = contactTranslations['contact.location.1'];
  const whatsappPhoneText = contactTranslations['contact.whatsapp'];
  const whatsappUrl = `https://wa.me/${whatsappPhoneText}?text=Hola%20me%20interesa%20tu%20servicio`;

  const fieldNameText = contactTranslations['contact.field.name'];
  const fieldEmailText = contactTranslations['contact.field.email'];
  const fieldSubjectText = contactTranslations['contact.field.phone'];
  const fieldMessageText = contactTranslations['contact.field.message'];

  const check1 = contactTranslations['contact.field.checkbox.1.title'];
  const check1_Option1 = contactTranslations['contact.field.checkbox.1.option.1'];
  const check1_Option2 = contactTranslations['contact.field.checkbox.1.option.2'];
  const check1_Option3 = contactTranslations['contact.field.checkbox.1.option.3'];

  const check2 = contactTranslations['contact.field.checkbox.2.title'];
  const check2_Option1 = contactTranslations['contact.field.checkbox.2.option.1'];
  const check2_Option2 = contactTranslations['contact.field.checkbox.2.option.2'];
  const check2_Option3 = contactTranslations['contact.field.checkbox.2.option.3'];

  const buttonSendText = contactTranslations['contact.button.send'];
  const messageReturnSuccessText = contactTranslations['contact.message.success'];
  const messageReturnErrorText = contactTranslations['contact.message.error'];

  const messageRequiredField1 = contactTranslations['contact.field.required.1'];
  const messageRequiredField2 = contactTranslations['contact.field.required.2'];
  const messageRequiredField3 = contactTranslations['contact.field.required.3'];
  const messageRequiredField4 = contactTranslations['contact.field.required.4'];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        selectedTypeOfInvestment: [...prevFormData.selectedTypeOfInvestment, name],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        selectedTypeOfInvestment: prevFormData.selectedTypeOfInvestment.filter((option) => option !== name),
      }));
    }
  };

  const handleTicketChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        selectedTicket: [...prevFormData.selectedTicket, name],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        selectedTicket: prevFormData.selectedTicket.filter((ticket) => ticket !== name),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    setCheckboxError('');

    const { contactName, email } = formData;
    if (!contactName) {
      setError(messageRequiredField1);
      return;
    }
    if (!email) {
      setError(messageRequiredField2);
      return;
    }

    if (formData.selectedTypeOfInvestment.length === 0) {
      setError(messageRequiredField3);
      return;
    }

    if (formData.selectedTicket.length === 0) {
      setError(messageRequiredField4);
      return;
    }

    try {
      const response = await ContactService.sendContactRequest(formData);
      setMessage(messageReturnSuccessText);
      setFormData({
        contactName: '',
        email: '',
        locale: navigator.language,
        phone: '',
        message: '',
        pageId: 1,
        selectedTypeOfInvestment: [],
        selectedTicket: [],
      });
    } catch (error) {
      setError(messageReturnErrorText || error.message);
    }
  };

  return (
    <div id="contact" className="contact-area">
      <div className="contact-inner area-padding">
        <div className="contact-overly"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="section-headline text-center">
                <h2>{contactTitleText}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Start contact icon column */}
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="contact-icon text-center">
                <div className="single-icon">
                  <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
                    <i className="fa"><FaWhatsapp /></i>
                    <p>
                      <FaPhone /> {phone1Text}
                    </p>
                  </a>
                </div>
              </div>
            </div>
            {/* Start contact icon column */}
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="contact-icon text-center">
                <div className="single-icon">
                  <i className="fa fa-envelope-o"></i>
                  <p>
                    <FaEnvelope /> {emailText}<br />
                  </p>
                </div>
              </div>
            </div>
            {/* Start contact icon column */}
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="contact-icon text-center">
                <div className="single-icon">
                  <i className="fa fa-map-marker"></i>
                  <p>
                    <FaMapMarkerAlt /> {locationText}<br />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Start contact form */}
            {message && <div className="alert alert-success">{message}</div>}
            {error && <div className="alert alert-danger">{error}</div>}
            {checkboxError && <div className="alert alert-danger">{checkboxError}</div>}
            <form onSubmit={handleSubmit} className="contactForm">
              <div className="col-md-6 col-sm-6 col-xs-12">

                <div className="form contact-form">
                  <div className="contactForm">
                    {/* Primer conjunto de checkbox */}
                    <div className="checkbox-group">
                      <h5>{check1}</h5>
                      <div className="custom-checkbox-wrapper">
                        <label>
                          <input
                            type="checkbox"
                            name="Profitability"
                            value="Profitability"
                            onChange={handleCheckboxChange}
                            className="custom-checkbox"
                          />{' '}
                          {check1_Option1}
                        </label>
                      </div>
                      <div className="custom-checkbox-wrapper">
                        <label>
                          <input
                            type="checkbox"
                            name="Flipping"
                            value="Flipping"
                            onChange={handleCheckboxChange}
                            className="custom-checkbox"
                          />{' '}
                          {check1_Option2}
                        </label>
                      </div>
                      <div className="custom-checkbox-wrapper">
                        <label>
                          <input
                            type="checkbox"
                            name="Purchase on credit"
                            value="Purchase on credit"
                            onChange={handleCheckboxChange}
                            className="custom-checkbox"
                          />{' '}
                          {check1_Option3}
                        </label>
                      </div>
                    </div>

                    {/* Segundo conjunto de checkbox */}
                    <div className="checkbox-group">
                      <h5>{check2}</h5>
                      <div className="custom-checkbox-wrapper">
                        <label>
                          <input
                            type="checkbox"
                            name="50k to 250k"
                            value="50k to 250k"
                            onChange={handleTicketChange}
                            className="custom-checkbox"
                          />{' '}
                          {check2_Option1}
                        </label>
                      </div>
                      <div className="custom-checkbox-wrapper">
                        <label>
                          <input
                            type="checkbox"
                            name="250k to 500k"
                            value="250k to 500k"
                            onChange={handleTicketChange}
                            className="custom-checkbox"
                          />{' '}
                          {check2_Option2}
                        </label>
                      </div>
                      <div className="custom-checkbox-wrapper">
                        <label>
                          <input
                            type="checkbox"
                            name="More than 500k"
                            value="More than 500k"
                            onChange={handleTicketChange}
                            className="custom-checkbox"
                          />{' '}
                          {check2_Option3}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="form contact-form">
                  <div className="contactForm">
                    <div className="form-group">
                      <input
                        type="text"
                        name="contactName"
                        className="form-control border-custom"
                        id="name"
                        placeholder={fieldNameText}
                        value={formData.contactName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control border-custom"
                        name="email"
                        id="email"
                        placeholder={fieldEmailText}
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control border-custom"
                        name="phone"
                        id="phone"
                        placeholder={fieldSubjectText}
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control border-custom"
                        name="message"
                        rows="5"
                        placeholder={fieldMessageText}
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="text-center ">
                      <button type="submit" className='border-custom-button'>{buttonSendText}</button>
                    </div>
                  </div>
                </div>
              </div>
              {/* End contact form */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
