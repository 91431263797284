import React from 'react';
import Navbar from '../navibar/Navbar';

const Header = ({ translations }) => {

  return (
    <header>
      <div id="sticker" className="header-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <Navbar translations={translations} />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
