import React, { useState } from 'react';
import NewsletterService from '../../services/NewslleterService';

const Newsletter = ({ translations }) => {

  const [formData, setFormData] = useState({
    email: '',
    locale: navigator.language,
  });

  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const objectTranslations = translations?.components?.newsletter?.translations || {};

  const newsletterTitleText = objectTranslations['newsletter.title'];
  const newsletterBodyText = objectTranslations['newsletter.body'];

  const contactTranslations = translations?.components?.contact?.translations || {};
  const messageReturnSuccessText = contactTranslations['contact.message.success'];
  const messageReturnErrorText = contactTranslations['contact.message.error'];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      await NewsletterService.sendNewsletterRequest(formData);
      setMessage(messageReturnSuccessText); 
      setFormData({
        email: '',
        locale: navigator.language,
      }); 
    } catch (error) {
      setError(messageReturnErrorText || error.message); 
    }
  };

  return (
    <div className="wellcome-area">
      <div className="well-bg">
        <div className="test-overly"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="wellcome-text">
                <div className="well-text text-center">
                  <h2>{ newsletterTitleText }</h2>
                  <p>
                    { newsletterBodyText }
                  </p>
                  {message && <div className="alert alert-success">{message}</div>}
                  {error && <div className="alert alert-danger">{error}</div>}
                  <div className="subs-feilds">
                    <div className="suscribe-input">
                      <form onSubmit={handleSubmit}>
                          <input
                            type="email"
                            className="email form-control width-80"
                            name="email"
                            id="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            data-rule="requered"
                            data-msg="Please enter a valid email"
                          />
                          <div className="validation"></div>
                        <button
                          type="submit"
                          id="submit"
                          className="add-btn width-20"
                        >Subscribe</button>
                        <div
                          id="msg_Submit"
                          className="h3 text-center hidden"
                        ></div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;