import React from 'react';

const Footer = ({ translations }) => {

  const contactTranslations = translations?.components?.contact?.translations || {};
  const phone1Text = contactTranslations['contact.phone.1'];
  const emailText = contactTranslations['contact.email.1'];
  const instagramUrlText = contactTranslations['contact.instagram.1'];
  const whatsappPhoneText = contactTranslations['contact.whatsapp'];
  const whatsappUrl = `https://wa.me/${whatsappPhoneText}?text=Hola%20me%20interesa%20tu%20servicio`;

  const objectTranslations = translations?.components?.portfolio?.translations || {};
  const portfolio1Image = objectTranslations['portfolio.image.1'];
  const portfolio2Image = objectTranslations['portfolio.image.2'];
  const portfolio3Image = objectTranslations['portfolio.image.3'];
  const portfolio4Image = objectTranslations['portfolio.image.4'];
  const portfolio5Image = objectTranslations['portfolio.image.5'];
  const portfolio6Image = objectTranslations['portfolio.image.6'];

  const footerTranslations = translations?.components?.footer?.translations || {};
  const footerMotivationPhraseText = footerTranslations['footer.motivation.phrase'];
  const footerTitleInfoText = footerTranslations['footer.title.info'];
  const footerBodyInfoText = footerTranslations['footer.body.info'];

  return (
    <footer>
      <div className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="footer-content">
                <div className="footer-head">
                  
                  <div className="footer-logo">
                    <img src="img/san-partners-black.svg" alt="San & Partners Footer Logo" className="footer-logo-img" />
                    <h2><span>San &</span> Partners</h2>
                  </div>
                  <p>
                    "{ footerMotivationPhraseText }"
                  </p>
                  <div className="footer-icons">
                    <ul>
                      <li>
                        <a href={ instagramUrlText } target="_blank"><i className="fa fa-instagram"></i></a>
                      </li>
                      
                      <li>
                        <a href={ whatsappUrl } target="_blank"><i className="fa fa-whatsapp"></i></a>
                      </li>
                      {/*
                      <li>
                        <a href="#" target="_blank"><i className="fa fa-google"></i></a>
                      </li>
                      <li>
                        <a href="#" target="_blank"><i className="fa fa-pinterest"></i></a>
                      </li>
                      */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* end single footer */}
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="footer-content">
                <div className="footer-head">
                  <h4>{ footerTitleInfoText }</h4>
                  <p>
                    { footerBodyInfoText }
                  </p>
                  <div className="footer-contacts">
                    <p><span>Tel:</span> { phone1Text }</p>
                    <p><span>Email:</span> { emailText }</p>
                  </div>
                </div>
              </div>
            </div>
            {/* end single footer */}
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="footer-content">
                <div className="footer-head">
                  <h4>Instagram</h4>
                  <div className="flicker-img">
                    <a href={ instagramUrlText } target="_blank"><img src={ portfolio1Image } alt="Instagram 1" /></a>
                    <a href={ instagramUrlText } target="_blank"><img src={ portfolio2Image } alt="Instagram 2" /></a>
                    <a href={ instagramUrlText } target="_blank"><img src={ portfolio3Image } alt="Instagram 3" /></a>
                    <a href={ instagramUrlText } target="_blank"><img src={ portfolio4Image } alt="Instagram 4" /></a>
                    <a href={ instagramUrlText } target="_blank"><img src={ portfolio5Image } alt="Instagram 5" /></a>
                    <a href={ instagramUrlText } target="_blank"><img src={ portfolio6Image } alt="Instagram 6" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-area-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="copyright text-center">
                <p>
                  &copy; Copyright <strong>San & Partners</strong>. All Rights Reserved
                </p>
              </div>
              <div className="credits">
                2024
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
